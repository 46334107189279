<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <table-custom
          ref="dataTable"
          :name="`custom_table_jobs`"
          :loading="dataTable.isLoading"
          :data="dataTable.dataSet"
          :options="dataTable.options"
        >
          <div slot="custom-actions" slot-scope="props">
            <div class="btn-group" style="height: 2em;">
              <inline-switch
                :id="`${props.row['ID']}-sw-job-enabled`"
                style="text-align: center;display: inline-block;"
                :value="+props.row['Enabled'] === 1"
                :hide-label="true"
                @changed="onJobEnabledChange(props.row)"
              />
              <button
                class="btn btn-dark btn-sm"
                style="text-align: center;display: inline-block;"
                @click="showModal(props.row.ID)"
                title="Display logs"
              >
                <font-awesome-icon icon="search" />
              </button>
            </div>
          </div>
        </table-custom>
      </b-container>
    </b-card>

    <b-modal ref="logs-modal" centered hide-footer size="xl" title="Logs">
      <table-custom
        ref="logsTable"
        :name="`custom_table_jobs_logs`"
        :loading="logsTable.isLoading"
        :data="logsTable.dataSet"
        :options="logsTable.options"
      >
      </table-custom>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

import { FORM_MODE } from '@/shared/constants'
import InlineSwitch from '@/components/InlineSwitch'

export default {
  name: 'Jobs',
  components: {
    TableCustom,
    InlineSwitch
  },
  data: function () {
    return {
      FORM_MODE: FORM_MODE,

      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            'ID',
            'Title',
            'Description',
            'Frequency',
            'Job Status',
            'Last Run',
            'Modified',
            'Actions'
          ],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      },
      logsTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: ['ID', 'Job', 'Description', 'Created'],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    }
  },
  computed: {},
  async mounted () {
    this.getData()
  },
  methods: {
    async onJobEnabledChange (row) {
      let enabled = +row['Enabled'] === 1 ? 0 : 1

      await this.$api.put(`job/${row.ID}`, { enabled: enabled })

      this.getData()
    },
    async getData () {
      let self = this

      let response = []
      this.dataTable.isLoading = true

      try {
        response = await this.$api.get('jobs')

        self.dataTable.isLoading = false

        if (response.length === 0) return

        self.dataTable.dataSet = response
      } catch (err) {
        self.dataTable.isLoading = false

        self.$form.msgBoxOk(err.message)
      }
    },

    editItem () {},

    async showModal (jobId) {
      this.$refs['logs-modal'].show()

      this.logsTable.isLoading = true

      let response = await this.$api.get(`job/${jobId}/logs`)

      this.logsTable.isLoading = false

      this.logsTable.dataSet = response
    },
    closeModal () {
      this.$refs['logs-modal'].hide()
    }
  },
  watch: {}
}
</script>

<style scoped></style>
